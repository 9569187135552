import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Power Snatch 3×6/arm`}</p>
    <p>{`DB OHS 3×6/arm`}</p>
    <p>{`OH Band Pull Aparts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`10-8-6-4-2-4-6-8-10 reps each of:`}</p>
    <p>{`DB Snatch per arm (40/25)`}</p>
    <p>{`T2B`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you would attend a 9:00am class please sign the sheet located on
the clip board by the whiteboard up front.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, August 5th we are having our Ville get together at the
Barnetts house, everyone is invited!  We’ll watch the final day of the
CrossFit Games, eat, swim, and we’ll even have a few chances to workout.
 Call, text or email Daniel for the address.  Family friendly so bring
the kids!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      